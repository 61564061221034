import React, { useState } from 'react';
import Header from '../../../Components/Home/Header/Header';
import Testimonial from '../../../Components/Home/Testimonial/Testimonial';
import Logo from '../../../Components/Home/Logo/Logo';
import Footer from '../../../Components/Home/Footer/Footer';
import './Geospatial.css';

const Geospatial = () => {
  // State to manage which card is expanded
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  // Define services data
  const services = [
    {
      title: 'Cartography, GIS Mapping Services',
      image: '/assets/gis cat.jpg',
      fullContent: (
        <>
          <ul>
            <li>
              <b>Land Base Mapping With Geo-Database:</b>
              <ul>
                <li>LULC Mapping</li>
                <li>Navigation Mapping</li>
                <li>Forest Mapping</li>
                <li>Agriculture Mapping</li>
                <li>Rural Development</li>
                <li>Town Planning</li>
                <li>Geological Survey & Mapping</li>
                <li>Corridor Mapping (Road , Railway , Other Network Features)</li>
                <li>Property task Survey & Mapping etc...</li>
              </ul>
            </li>
            <li>
              <b>Utility Survey  & Mapping :</b>
              <ul>
                <li>Electrical Survey & Mapping</li>
                <li>Telecom</li>
                <li>Water Resource Management</li>
                <li>Oil & Gas</li>
              </ul>
            </li>

          </ul>
        </>
      ),
      previewContent: (
        <ul>
          <li><b>Land Base Mapping:</b></li>
          <li>LULC Mapping</li>
          <li>Navigation Mapping</li>
        </ul>
      )
    },
    {
      title: 'Remote Sensing & Photogrammetry Services',
      image: '/assets/remotesensing.jpg',
      fullContent: (
        <>
          <ul>
            <li>Image Processing</li>
            <li>Ortho Processing & Mosaic</li>
            <li>Satellite Data Processing</li>
            <li>Geospatial Data Analysis</li>
            <li>
              <b>Photogrammetry:</b>
              <ul>
                <li>Arial Triangulation (AT)/DTM/DEM/DSM/TIN Generation</li>
                <li>3D Vector Mapping</li>
              </ul>
            </li>
            <li>
              <b>LiDAR Data Processing , Classification & Modeling:</b>
              <ul>
                <li>UAV Data Processing</li>
                <li>Ground/Non-Ground Classification</li>
                <li>Powerline Classification</li>
                <li>Bareer Mapping</li>
                <li>Planimetric Feature Extraction</li>
                <li>Forest Mapping</li>
                <li>Watershed Classification</li>
                <li>Railroad Corridor Mapping</li>
                <li>Geological Modeling</li>
              </ul>
            </li>

          </ul>
        </>
      ),
      previewContent: (
        <ul>
          <li>Photogrammetry</li>
          <li>Image Processing</li>
        </ul>
      )
    },
    {
      title: 'CAD/ BIM / MEP & Engineering Services',
      image: '/assets/bim1.jpg',
      fullContent: (
        <>
          <ul>
            <li>
              <b>CAD Services:</b>
              <ul>
                <li>CAD Drafting & 3D Modeling</li>
                <li>Architech Design & Engineering Services</li>
              </ul>
            </li>
            <li><b>BIM & MEP Services:</b></li>
            <li>BIM Studio for Civil Construction</li>
            <li>MEPF, Scan to BIM, CAD to BIM, Leica Cyclone</li>
            <li> MEP Services (HVAC, Plumbing, Fire Fighting, Electrical)</li>
          </ul>
        </>
      ),
      previewContent: (
        <ul>
          <li>CAD Services</li>
          <li>BIM Modeling (REVIT)</li>
        </ul>
      )
    }
  ];

  return (
    <div>
      <Header />
      <div className="service-cards-container">
        {services.map((service, index) => (
          <div className="card" key={index}>
            <img src={service.image} alt={service.title} className="card-image" />
            <div className="card-content">
              <h3>{service.title}</h3>
              {/* Show limited content by default */}
              {expandedCard === index ? service.fullContent : service.previewContent}
              <a onClick={() => toggleCard(index)} href="#!">
                {expandedCard === index ? 'SHOW LESS ←' : 'READ MORE →'}
              </a>
            </div>
          </div>
        ))}
      </div>
      <Testimonial />
      <Logo />
      <Footer />
    </div>
  );
}

export default Geospatial;
