import React, { useState } from 'react';
import './Testimonial.css';
import bgImg from '../Images/white-bg.png';

const testimonials = [
    {
        name: 'Sofia Muller',
        role: 'Web Developer',
        feedback: "The support and guidance I received were exceptional. They exceeded my expectations and delivered on time. I felt valued and respected throughout the entire process.",
        avatar: 'https://randomuser.me/api/portraits/women/50.jpg',
    },
    {
        name: 'Amir Mustafa',
        role: 'Marketing Manager',
        feedback:  "Working with them was a breeze! They kept me updated throughout the project and delivered exactly what I was looking for. I can't thank them enough!",
        avatar: 'https://randomuser.me/api/portraits/men/50.jpg',
    },
    {
        name: 'Elena Ivanova ',
        role: 'Product Designer',
        feedback: "I was impressed by their creativity and attention to detail. They transformed my ideas into a reality.  It's refreshing to work with a team that truly cares about their clients.",
        avatar: 'https://randomuser.me/api/portraits/women/51.jpg',
    },
   
];

export default function Testimonial() {
    const [current, setCurrent] = useState(0);
    const length = testimonials.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
        <div className="testimonial-container"  style={{ backgroundImage: `url(${bgImg})` }}>
            <h2> Our Customers Review</h2>
            <div className="testimonial-slider">
                <button className="prev" onClick={prevSlide}>&#10094;</button>
                {testimonials.map((testimonial, index) => (
                    <div
                        className={
                            index === current
                                ? 'testimonial active'
                                : 'testimonial'
                        }
                        key={index}
                    >
                        {index === current && (
                            <>
                                <div className="testimonial-avatars">
                                    {testimonials.map((t, i) => (
                                        <img
                                            key={i}
                                            src={t.avatar}
                                            alt={t.name}
                                            className={`avatar ${i === current ? 'active' : ''}`}
                                        />
                                    ))}
                                </div>
                                <blockquote>
                                    <p className="para" style={{fontSize:"1.2rem"}}>"{testimonial.feedback}"</p>
                                </blockquote>
                                <h3 className="heading">{testimonial.name}</h3>
                                <span className="span">{testimonial.role}</span>
                                <div className="stars">
                                    <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                                </div>
                            </>
                        )}
                    </div>
                ))}
                <button className="next" onClick={nextSlide}>&#10095;</button>
            </div>
        </div>
    );
}
