import React, { useState } from 'react';
import Header from '../Components/Home/Header/Header';
import Footer from '../Components/Home/Footer/Footer';
import Logo from '../Components/Home/Logo/Logo';
import './Career.css';
import axios from 'axios';

const Career = () => {
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');
    const [message, setMessage] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type !== "application/pdf") {
            alert("Please upload a PDF file");
            setFile(null);
        } else {
            setFile(selectedFile);
        }
    };

    const validateForm = () => {
        if (!name) {
            alert("Name is required");
            return false;
        }
        if (!email) {
            alert("Email is required");
            return false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            alert("Email is invalid");
            return false;
        }
        if (!phone) {
            alert("Phone number is required");
            return false;
        }
        if (!/^\d+$/.test(phone)) {
            alert("Phone number should contain only digits");
            return false;
        }
        if (!position) {
            alert("Position is required");
            return false;
        }
        if (!message) {
            alert("Message is required");
            return false;
        }
        if (!file) {
            alert("CV upload is required");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('phone', phone);
                formData.append('position', position);
                formData.append('message', message);
                if (file) {
                    formData.append('file', file);
                }
    
                const response = await axios.post('http://localhost:5000/apply', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                console.log('Form submitted successfully:', response.data);
                // Reset form fields
                setName('');
                setEmail('');
                setPhone('');
                setPosition('');
                setMessage('');
                setFile(null);
            } catch (error) {
                console.error('Error submitting form:', error.response ? error.response.data : error.message);
                alert('Error submitting form: ' + (error.response ? error.response.data.error : error.message));
            }
        }
    };
    
    return (
        <>
            <Header />
            <div className="contact-container2">
                <div className="contact-form2">
                    <h2>Choose Your Career</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <input 
                                type="text" 
                                placeholder="Your Name" 
                                value={name}
                                onChange={(e) => setName(e.target.value)} 
                            />
                            <input 
                                type="email" 
                                placeholder="Enter E-Mail" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                        </div>
                        <div className="form-row">
                            <input 
                                type="text" 
                                placeholder="Phone Number" 
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)} 
                            />
                            <select 
                                value={position} 
                                onChange={(e) => setPosition(e.target.value)}
                            >
                                <option value="">Select Position</option>
                                <option value="Full Stack Developer">Full Stack Developer</option>
                                <option value="Python">Python Developer</option>
                                <option value="AI/ML Engineer">AI/ML Engineer</option>
                                <option value="Cloud Architect">Cloud Architect</option>
                                <option value="Database Administrator">Database Administrator</option>
                                <option value="Ethical Hacker">Ethical Hacker</option>
                            </select>
                        </div>

                        <textarea 
                            placeholder="Write Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} 
                        ></textarea>

                        <div className="form-row file-upload-row">
                            <label htmlFor="resume">Upload Your CV</label>
                            <input 
                                type="file" 
                                id="resume" 
                                accept="application/pdf" 
                                onChange={handleFileChange}
                            />
                        </div>

                        <button className='btn2' type="submit">Apply Now</button>
                    </form>
                </div>
            </div>

            <Logo />
            <Footer />
        </>
    );
};

export default Career;
