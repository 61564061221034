// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    width: 100%;
    overflow: hidden;
    /* background-color: #0056ff; */
    padding: 20px 0;
  }
  
  .slider {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    animation: slide 20s linear infinite;
  }
  
  .slider img {
    max-height: 80px;
    transition: transform 0.3s ease-in-out;
  }
  
  .slider img:hover {
    transform: scale(1.1);
  }
  
  /* Keyframes for sliding effect */
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .slider img {
      max-height: 60px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Home/Logo/Logo.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,+BAA+B;IAC/B,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,SAAS;IACT,oCAAoC;EACtC;;EAEA;IACE,gBAAgB;IAChB,sCAAsC;EACxC;;EAEA;IACE,qBAAqB;EACvB;;EAEA,iCAAiC;EACjC;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,4BAA4B;IAC9B;EACF;;EAEA,uBAAuB;EACvB;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".slider-container {\r\n    width: 100%;\r\n    overflow: hidden;\r\n    /* background-color: #0056ff; */\r\n    padding: 20px 0;\r\n  }\r\n  \r\n  .slider {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    gap: 40px;\r\n    animation: slide 20s linear infinite;\r\n  }\r\n  \r\n  .slider img {\r\n    max-height: 80px;\r\n    transition: transform 0.3s ease-in-out;\r\n  }\r\n  \r\n  .slider img:hover {\r\n    transform: scale(1.1);\r\n  }\r\n  \r\n  /* Keyframes for sliding effect */\r\n  @keyframes slide {\r\n    0% {\r\n      transform: translateX(0);\r\n    }\r\n    100% {\r\n      transform: translateX(-100%);\r\n    }\r\n  }\r\n  \r\n  /* Responsive styling */\r\n  @media (max-width: 768px) {\r\n    .slider img {\r\n      max-height: 60px;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
