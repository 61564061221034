// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics-section { /* Use the correct path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 0;
    text-align: center;
    color: white;
}

.stat-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap; /* Ensure it wraps on smaller screens */
    max-width: 1200px;
    margin: 0 auto;
}

.stat-item {
    margin: 20px;
    flex: 1 1 200px; /* Responsive design, minimum width */
    text-align: center;
}

.stat-item h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.stat-item p {
    font-size: 1.2rem;
    margin: 0;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .stat-item h2 {
        font-size: 2rem;
    }

    .stat-item p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .stat-item h2 {
        font-size: 1.5rem;
    }

    .stat-item p {
        font-size: 0.9rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Home/StatisticsSection/StatisticsSection.css"],"names":[],"mappings":"AAAA,sBAAsB,uCAAuC;IACzD,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe,EAAE,uCAAuC;IACxD,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,eAAe,EAAE,qCAAqC;IACtD,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,SAAS;AACb;;AAEA,qCAAqC;AACrC;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".statistics-section { /* Use the correct path to your image */\r\n    background-size: cover;\r\n    background-position: center;\r\n    background-repeat: no-repeat;\r\n    padding: 50px 0;\r\n    text-align: center;\r\n    color: white;\r\n}\r\n\r\n.stat-container {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    flex-wrap: wrap; /* Ensure it wraps on smaller screens */\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.stat-item {\r\n    margin: 20px;\r\n    flex: 1 1 200px; /* Responsive design, minimum width */\r\n    text-align: center;\r\n}\r\n\r\n.stat-item h2 {\r\n    font-size: 2.5rem;\r\n    margin-bottom: 10px;\r\n    font-weight: bold;\r\n}\r\n\r\n.stat-item p {\r\n    font-size: 1.2rem;\r\n    margin: 0;\r\n}\r\n\r\n/* Media Queries for responsiveness */\r\n@media (max-width: 768px) {\r\n    .stat-item h2 {\r\n        font-size: 2rem;\r\n    }\r\n\r\n    .stat-item p {\r\n        font-size: 1rem;\r\n    }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n    .stat-item h2 {\r\n        font-size: 1.5rem;\r\n    }\r\n\r\n    .stat-item p {\r\n        font-size: 0.9rem;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
