import React from 'react'
import Header from '../../../Components/Home/Header/Header'
import Testimonial from '../../../Components/Home/Testimonial/Testimonial'
import Logo from '../../../Components/Home/Logo/Logo'
import Footer from '../../../Components/Home/Footer/Footer'
import { Link } from 'react-router-dom';

const Domain = () => {
  return (
    <div>
      <Header />
      <div className="services-container1">

        <div className="service-card1">
          <img src="/assets/healthCare.jpg" alt="Artificial Intelligence Services" className="service-image" />
          <h3 className="service-title">Health Care</h3>
          <p className="service-description">Electronic Health Records (EHR): Systems to manage
 patient data, medical history, and treatment plans.
 Healthcare 
Telemedicine: Platforms for remote consultation and
 diagnosis.
 Health Information Systems: Integration of various
 healthcare systems for seamless data exchange.
 Patient Portals: Platforms for patients to access their
 medical records and communicate with healthcare
 providers
          </p>
          <Link to="/services/ai" className="service-link">Read More →</Link>
        </div>


        <div className="service-card1">
          <img src="/assets/banking.jpg" alt="IOT Services" className="service-image" />
          <h3 className="service-title">Banking</h3>
          <p className="service-description">
          Core Banking Systems  : Platforms to manage customer
 accounts, transactions, and banking operations. 
Online Banking : Web and mobile applications for
 banking services. Financial Banking
 Fraud Detection : AI and machine learning tools to
 detect and prevent fraudulent activities.
 Blockchain : For secure transactions and
 decentralized finance (DeFi) applications. 
Risk Management: Tools for assessing and managing
 financial risks
          </p>
          <Link to="/services/iot" className="service-link">Read More →</Link>
        </div>


        <div className="service-card1">
          <img src="/assets/hospital1.webp" alt="Software Development" className="service-image" />
          <h3 className="service-title">Hospitality & Travel </h3>
          <p className="service-description">
          Tools for
 managing hotel operations and guest services. 
Booking and Reservation Systems: Platforms for
 managing reservations and bookings. Hospitality &
 Travel
 Customer Experience Management: Tools for
 enhancing guest experiences and personalization. 
Travel Management Systems: Solutions for managing
 travel bookings and itineraries.
 Revenue Management Systems: Tools for optimizing
 pricing and revenue. 
Event Management Software: Solutions for planning
 and managing events and conferences.

          </p>
          <Link to="/services/software-development" className="service-link">Read More →</Link>
        </div>

        <div className="service-card1">
          <img src="/assets/logistic.jpg" alt="Web Designing" className="service-image" />
          <h3 className="service-title">Transportation & Logistic</h3>
          <p className="service-description">

          Tools for managing and
 tracking vehicle fleets. Transportation & Logistic 
Route Optimization: AI tools for optimizing delivery
 routes and schedules. 
Warehouse Management: Solutions for managing
 warehouse operations and inventory.
 Transportation Management Systems (TMS) :
 Platforms for planning and executing the
 movement of goods.
 Telematics: Systems for monitoring vehicle
 performance and driver behavior. 
Supply Chain Visibility: Tools for tracking
 shipments and managing logistics.
          </p>
          <Link to="/services/software-development" className="service-link">Read More →</Link>
        </div>

        <div className="service-card1">
          <img src="/assets/retail.jpg" alt="Digital Marketing" className="service-image" />
          <h3 className="service-title">Retail & ECom</h3>
          <p className="service-description">
          E-Commerce Platforms: Websites and apps for online
 shopping. 
Point of Sale (POS) Systems: Integrated systems for
 in-store sales transactions.
 Inventory Management: Solutions for tracking and
 managing stock levels. Retail & eCommerce 
Customer Relationship Management (CRM) : Tools for
 managing customer interactions and sales. 
Personalization Engines: AI-driven systems for
 personalized shopping experiences.
 Supply Chain Management: Tools for managing
 logistics and supply chains.

          </p>
          <Link to="/services/software-development" className="service-link">Read More →</Link>
        </div>

        <div className="service-card1">
          <img src="/assets/e-governance.webp" alt="Mobile Application" className="service-image" />
          <h3 className="service-title">E Governance</h3>
          <p className="service-description">

          E-Government Solutions: Platforms for providing
 government services online. 
Public Safety Systems: Tools for managing emergency
 services and public safety operations. Government &
 PSU
 Citizen Portals: Platforms for citizens to access
 government services and information. 
Data Management Systems: Solutions for managing
 government data and records. 
Smart City Solutions: IoT and AI technologies for
 managing urban infrastructure and services
 Regulatory Compliance Tools: Systems for ensuring
 compliance with regulations and policies.
          </p>
          <Link to="/services/software-development" className="service-link">Read More →</Link>
        </div>
      </div>
      <Testimonial />
      <Logo />
      <Footer />
    </div>
  )
}

export default Domain