import React from 'react'
import Header from '../../Components/Home/Header/Header'
import Testimonial from '../../Components/Home/Testimonial/Testimonial'
import Logo from '../../Components/Home/Logo/Logo'
import Footer from '../../Components/Home/Footer/Footer'
import './Award.css'

const Award = () => {
  return (
    <div>
        <Header/>
        <div className="card-container5">
      <div className="card5">
        <img src="/assets/award-1.jpg" alt="Card 1" className="card-image5" />
        
      </div>

      <div className="card5">
        <img src="/assets/award-2.jpg" alt="Card 2" className="card-image5" />
        
      </div>

      <div className="card5">
        <img src="/assets/award-3.jpg" alt="Card 3" className="card-image5" />
        
      </div>

      <div className="card5">
        <img src="/assets/award-4.jpg" alt="Card 4" className="card-image5" />
       
      </div>
    </div>
        <Testimonial/>
        <Logo/>
        <Footer/>
    </div>
  )
}

export default Award