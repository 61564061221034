import React from 'react'
import '../src/App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home'
import Overview from './Layouts/Overview/Overview';
import Testimonials from './Layouts/Testimonials/Testimonials';
import Contact from './Contact/Contact';
import Career from './Career/Career';
import ItService from './Layouts/Services/IT Services/ItService';
import Geospatial from './Layouts/Services/Geospatial Services/Geospatial';
import Domain from './Layouts/Industries/Domain Offerings/Domain';
import Award from './Layouts/Awards/Award';


const App = () => {
  return (
	<div>
		<Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Overview" element={<Overview />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/Awards" element={<Award />} />
        <Route path="/ItService" element={<ItService />} />
        <Route path="/GeospatialService" element={<Geospatial />} />
        <Route path="/Domain" element={<Domain />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Career" element={<Career />} />
       
       
      </Routes>
    </Router>
	</div>
  )
}

export default App