import React from 'react';
import './About.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCloud, faLaptopCode, faPalette, faBullhorn, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

const services = [
  {
    id: 1,
    title: 'Artificial Intelligence',
    description: 'Monotonectally synergize grants to business visualize strategic infomediaries',
    icon: faBrain,
  },
  {
    id: 2,
    title: 'IOT Services',
    description: 'Monotonectally synergize grants to business visualize strategic infomediaries',
    icon: faCloud,
  },
  {
    id: 3,
    title: 'Software Development',
    description: 'Monotonectally synergize grants to business visualize strategic infomediaries',
    icon: faLaptopCode,
  },
  {
    id: 4,
    title: 'Web Designing',
    description: 'Monotonectally synergize grants to business visualize strategic infomediaries',
    icon: faPalette,
  },
  {
    id: 5,
    title: 'Digital Marketing',
    description: 'Monotonectally synergize grants to business visualize strategic infomediaries',
    icon: faBullhorn,
  },
  {
    id: 6,
    title: 'Mobile Application',
    description: 'Monotonectally synergize grants to business visualize strategic infomediaries',
    icon: faMobileAlt,
  },
];

const About = () => {
  return (
    <div className="services-container">
      <h1 className="title">Ensuring Your Success Trusted IT Services Source</h1>
      <div className="services-wrapper">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="icon">
              <FontAwesomeIcon icon={service.icon} size="2x" />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <a href="#" className="learn-more">
              →
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
