import React from 'react'
import AboutUs from '../../Components/Home/AboutUs/AboutUs'
import Header from '../../Components/Home/Header/Header'
import Footer from '../../Components/Home/Footer/Footer'
import Team from '../../Components/Home/Team/Team'
import StatisticsSection from '../../Components/Home/StatisticsSection/StatisticsSection'
import Logo from '../../Components/Home/Logo/Logo'
import Testimonial from '../../Components/Home/Testimonial/Testimonial'



const Overview = () => {
  return (
    <div>
        <Header/>
       <AboutUs/>
       <Team/>
       <StatisticsSection/>
       <Testimonial/>
       <Logo/>
       <Footer/>
    </div>
  )
}

export default Overview