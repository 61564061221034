import React from 'react'
import Header from '../../Components/Home/Header/Header'
import Testimonial from '../../Components/Home/Testimonial/Testimonial'
import Logo from '../../Components/Home/Logo/Logo'
import Footer from '../../Components/Home/Footer/Footer'



const Testimonials = () => {
  return (
    <div>
        <Header/>
        <Testimonial/>
        <Logo/>
        <Footer/>
    </div>
  )
}

export default Testimonials