// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container5 {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    margin: 20px; 

  }
  
  .card5 {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px; 
    flex: 1 1 220px; 
    max-width: 400px; 
    transition: transform 0.3s; 
    
  }
  
  .card5:hover {
    transform: translateY(-5px); 
  }
  
  .card-image5 {
    width: 100%; 
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  
  @media (max-width: 768px) {
    .card5 {
      max-width: 90%; 
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Layouts/Awards/Award.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,YAAY;;EAEd;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,0BAA0B;;EAE5B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,WAAW;IACX,2BAA2B;IAC3B,4BAA4B;EAC9B;;;EAGA;IACE;MACE,cAAc;IAChB;EACF","sourcesContent":[".card-container5 {\r\n    display: flex;\r\n    flex-wrap: wrap; \r\n    justify-content: center; \r\n    margin: 20px; \r\n\r\n  }\r\n  \r\n  .card5 {\r\n    background: white;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    margin: 15px; \r\n    flex: 1 1 220px; \r\n    max-width: 400px; \r\n    transition: transform 0.3s; \r\n    \r\n  }\r\n  \r\n  .card5:hover {\r\n    transform: translateY(-5px); \r\n  }\r\n  \r\n  .card-image5 {\r\n    width: 100%; \r\n    border-top-left-radius: 8px;\r\n    border-top-right-radius: 8px;\r\n  }\r\n  \r\n  \r\n  @media (max-width: 768px) {\r\n    .card5 {\r\n      max-width: 90%; \r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
