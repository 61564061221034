import React from 'react'
import Header from '../../../Components/Home/Header/Header'
import Testimonial from '../../../Components/Home/Testimonial/Testimonial'
import Logo from '../../../Components/Home/Logo/Logo'
import Footer from '../../../Components/Home/Footer/Footer'
import { Link } from 'react-router-dom';
import './ItService.css'



const ItService = () => {
    return (
        <div>
            <Header />
            <div className="services-container1">

                <div className="service-card1">
                    <img src="/assets/AI.jpg" alt="Artificial Intelligence Services" className="service-image" />
                    <h3 className="service-title">Artificial Intelligence Services</h3>
                    <p className="service-description">
                        AI-as-a-Service (AlaaS) is a cloud-based delivery model that provides access to artificial
                        intelligence capabilities without requiring extensive upfront investments in hardware, software,
                        or data science expertise. AlaaS platforms offer a range of AI services, including machine learning.
                    </p>
                    <Link to="/services/ai" className="service-link">Read More →</Link>
                </div>


                <div className="service-card1">
                    <img src="/assets/iot.jpg" alt="IOT Services" className="service-image" />
                    <h3 className="service-title">IOT Services</h3>
                    <p className="service-description">
                        Internet of Things (IoT) refers to the interconnected network of physical devices, vehicles,
                        home appliances, and other objects embedded with electronics, software, sensors, and network
                        connectivity. These devices collect and exchange data, enabling remote monitoring, control, and automation.
                    </p>
                    <Link to="/services/iot" className="service-link">Read More →</Link>
                </div>


                <div className="service-card1">
                    <img src="/assets/software.jpg" alt="Software Development" className="service-image" />
                    <h3 className="service-title">Software Development</h3>
                    <p className="service-description">
                        By leveraging AI technologies like machine learning, natural language processing, and computer
                        vision, you can automate tasks, improve customer experiences. From chatbots to predictive
                        analytics and image recognition, AI offers a wide range of applications to help your business.
                    </p>
                    <Link to="/services/software-development" className="service-link">Read More →</Link>
                </div>

                <div className="service-card1">
                    <img src="/assets/webdesigning.jpg" alt="Web Designing" className="service-image" />
                    <h3 className="service-title">Web Designing</h3>
                    <p className="service-description">
                        Our websites are developed using the latest technologies and industry trends including ASP.net,
                        PHP, MySQL and SQL Server etc in order to give you the best . We have an excellent team of
                        website development professionals who are well experienced to develop websites of any complexity.
                    </p>
                    <Link to="/services/software-development" className="service-link">Read More →</Link>
                </div>

                <div className="service-card1">
                    <img src="/assets/digitalmarketing.jpg" alt="Digital Marketing" className="service-image" />
                    <h3 className="service-title">Digital Marketing</h3>
                    <p className="service-description">
                        Digital marketing is the practice of promoting products or services using digital channels like
                        search engines, social media, email,and websites. It involves various strategies such as search
                        engine optimization (SEO), pay-per-click (PPC) advertising, content marketing, social media marketing,
                        and email marketing.
                    </p>
                    <Link to="/services/software-development" className="service-link">Read More →</Link>
                </div>

                <div className="service-card1">
                    <img src="/assets/mobile-application.jpg" alt="Mobile Application" className="service-image" />
                    <h3 className="service-title">Mobile Application</h3>
                    <p className="service-description">
                        We have dedicated mobile app development programmers who have ample experience in this field. Like
                        any other project that we undertake, mobile app development follows a strict schedule and structure,
                        so that you get the product delivered on time. You may not find this level of dedication development Services.
                    </p>
                    <Link to="/services/software-development" className="service-link">Read More →</Link>
                </div>
            </div>
            <Testimonial />
            <Logo />
            <Footer />
        </div>
    )
}

export default ItService