// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-container1 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .service-card1 {
    width: 30%;
    background-color: #f4f9fc;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-card1:hover {
    transform: translateY(-10px);
  }
  
  .service-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .service-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 15px 0;
    color: #0b274f;
  }
  
  .service-description {
    font-size: 1rem;
    color: #5a5a5a;
    margin-bottom: 20px;
  }
  
  .service-link {
    text-decoration: none;
    font-weight: bold;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .service-link:hover {
    color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .service-card1 {
      width: 45%;
    }
  }
  
  @media (max-width: 768px) {
    .service-card1 {
      width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Layouts/Services/IT Services/ItService.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,aAAa;EACf;;EAEA;IACE,UAAU;IACV,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,0CAA0C;IAC1C,+BAA+B;EACjC;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;IACd,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB;;EAEA,sBAAsB;EACtB;IACE;MACE,UAAU;IACZ;EACF;;EAEA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":[".services-container1 {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    flex-wrap: wrap;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .service-card1 {\r\n    width: 30%;\r\n    background-color: #f4f9fc;\r\n    margin: 20px;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\r\n    transition: transform 0.3s ease;\r\n  }\r\n  \r\n  .service-card1:hover {\r\n    transform: translateY(-10px);\r\n  }\r\n  \r\n  .service-image {\r\n    width: 100%;\r\n    height: auto;\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  .service-title {\r\n    font-size: 1.5rem;\r\n    font-weight: bold;\r\n    margin: 15px 0;\r\n    color: #0b274f;\r\n  }\r\n  \r\n  .service-description {\r\n    font-size: 1rem;\r\n    color: #5a5a5a;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .service-link {\r\n    text-decoration: none;\r\n    font-weight: bold;\r\n    color: #007bff;\r\n    transition: color 0.3s ease;\r\n  }\r\n  \r\n  .service-link:hover {\r\n    color: #0056b3;\r\n  }\r\n  \r\n  /* Responsive Design */\r\n  @media (max-width: 1200px) {\r\n    .service-card1 {\r\n      width: 45%;\r\n    }\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .service-card1 {\r\n      width: 100%;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
