import React, { useEffect, useRef } from 'react';
import './Logo.css';


import bkashLogo from '../Images/vendor-1.png';
import zamanLogo from '../Images/vendor-2.jpg';
import dreamLogo from '../Images/vendor-3.jpg';
import boisakhLogo from '../Images/vendor-4.jpg';
import shorotLogo from '../Images/vendor-5.png';

const Logo = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollAmount = 0;
    const scrollMax = slider.scrollWidth - slider.clientWidth;
    
    const slide = () => {
      if (scrollAmount >= scrollMax) {
        scrollAmount = 0;
      } else {
        scrollAmount += 1;
      }
      slider.scrollLeft = scrollAmount;
    };

    const interval = setInterval(slide, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-container">
      <div className="slider" ref={sliderRef}>
        <img src={bkashLogo} alt="bkash logo" />
        <img src={zamanLogo} alt="zaman logo" />
        <img src={dreamLogo} alt="dream logo" />
        <img src={boisakhLogo} alt="boisakh logo" />
        <img src={shorotLogo} alt="shorot logo" />
        {/* Repeat the images for continuous effect */}
        <img src={bkashLogo} alt="bkash logo" />
        <img src={zamanLogo} alt="zaman logo" />
        <img src={dreamLogo} alt="dream logo" />
        <img src={boisakhLogo} alt="boisakh logo" />
        <img src={shorotLogo} alt="shorot logo" />
      </div>
    </div>
  );
};

export default Logo;
