import React from 'react';
import './Team.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import ceo from '../Images/CEO.jpg';
import MD from '../Images//MD.jpg';
import Director from '../Images/director.jpg';
import cmo from '../Images/CMO.jpg';
import bgImg from '../Images/white-bg.png';
import advisor from '../Images/advisor.jpg';
import advisor1 from '../Images/pc.jpeg';
import advisor3 from '../Images/advisor-3.png'

const Team = () => {
  return (
    <section className="team-section"  style={{ backgroundImage: `url(${bgImg})` }}>
      <h2 className="team-title">Our Management</h2>
      <div className="team-wrapper">
        {/* Team member 1 */}
        <div className="team-card">
          <img
            src={MD}
            alt="Connie Diaz"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mr. Suryakant Behera</h3>
            <p>Managing Director</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>

        {/* Team member 2 */}
        <div className="team-card">
          <img
            src={Director}
            alt="James E. Huey"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mrs. Soma Kar</h3>
            <p>Director</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>

        {/* Team member 3 */}
        <div className="team-card">
          <img
            src={ceo}
            alt="Jone D. Alexon"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mr. Subodha Kumar Sahoo</h3>
            <p>Chief Executive Officer</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>

        {/* Team member 4 */}
        <div className="team-card">
          <img
            src={cmo}
            alt="June D. Vargas"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mr. Prabhakar Behera</h3>
            <p>Chief Marketing Officer</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>

        <div className="team-card">
          <img
            src={advisor}
            alt="James E. Huey"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mr. Kishore Kumar Mohanty</h3>
            <p>Advisor</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>

        <div className="team-card">
          <img
            src={advisor1}
            alt="James E. Huey"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mr. Pramod Chandra Pattnaik</h3>
            <p>Advisor</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>

        <div className="team-card">
          <img
            src={advisor3}
            alt="James E. Huey"
            className="team-img"
          />
          <div className="team-info">
            <h3>Mr. Chandramani Behera</h3>
            <p>Advisor</p>
          </div>
          <div className="team-share">
            <FontAwesomeIcon icon={faShareAlt} size="lg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
