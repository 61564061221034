import React, { useState } from 'react';
import './VideoSection.css';
import video from '../Images/TTS-bg.png'; // Use this for both background and popup

export default function VideoSection() {
    const [showVideo, setShowVideo] = useState(false);

    const handleVideoClick = () => {
        setShowVideo(true);
    };

    const closeVideo = () => {
        setShowVideo(false);
    };

    return (
        <div className="video-section-container">
            {/* <video autoPlay loop muted className="background-video">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            <img className="img-1" src={video} alt="bg-img" />

            {/* Overlay content */}
            <div className="content-overlay">
                <h2 className="heading-text">Our Best Working Process System</h2>
                <p className="subheading-text">with Team Expert Leaders</p>
                <button onClick={handleVideoClick} className="play-button">
                    <i className="fas fa-play play-icon"></i>
                </button>
            </div>

            {/* Popup video */}
            {showVideo && (
                <div className="video-popup">
                    <div className="video-wrapper">
                        <button onClick={closeVideo} className="close-video">&times;</button>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/fQXzAgTElvI?si=_4LqaAEyU11ncB2M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            )}
        </div>
    );
}
