import React from 'react';
import './Hero.css';
import bgImg from '../Images/bg-img.png';

const Hero = () => {
  return (
    <div className="hero-container" style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="hero-content">
        <p>IT SOLUTION AGENCY IN INDIA</p>
        <h1>BUSINESS INNOVATION WITH IT EXPERT</h1>
        <p>
          Professionally optimize interdependent intellectual interoperable connect
          best practices. Progressively fabricate done
        </p>
        <div className="hero-buttons">
          <button className="btn-primary">Get Started</button>
          <button className="btn-secondary">Learn More</button>
        </div>
      </div>
      
    </div>
  );
};

export default Hero;

