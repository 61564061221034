import React from 'react';
import './Footer.css';
import logo from '../Images/tts_logo.png';
import { FaFacebook } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"; 
import { IoMdMail } from "react-icons/io";
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* <div className="footer-column">
          <h2 className="footer-title">
            <img src={logo } alt="Toptech Logo" className="footer-logo" /> TTS
          </h2>
          <p className="footer-description" style={{color:"#B6B9C0"}}>
            TTS As a leading IT company, we specialize <br />
            in delivering innovative and reliable services that drive efficiency, productivity, and growth for our clients.
          </p>
          <button className="footer-btn">Discover More</button>
        </div> */}
        
        
        <div className="footer-column get-in-touch">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Get In Touch</h4>
          <ul className="get-in-touch-info">
            <li><FaMapMarkerAlt className="contact-icon" /> Sailashree Vihar, Bhubaneswar, Odisha 751021</li>
            <li><IoMdMail className="contact-icon" /> info@totaltechnology <br />system.com</li>
            <li><FaPhoneAlt className="contact-icon" /> +91-8260205005</li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Company</h4>
          <ul>
            <li><Link to="/Overview">About</Link></li>
            <li><Link to="/ItService">Services</Link></li>
            <li><Link to="/Domain">Industries</Link></li>
            <li><Link to="/Contact">Contact</Link></li>
            <li><Link to="/Career">Career</Link></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Our Services</h4>
          <ul>
            <li><a href="#it-solutions">IT Solutions</a></li>
            <li><a href="#cyber-security">Cyber Security</a></li>
            <li><a href="#digital-marketing">Digital Marketing</a></li>
            <li><a href="#brand-identity">Brand Identity</a></li>
            <li><a href="#seo-marketing">SEO Marketing</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Newsletter</h4>
          <p style={{color:"#B6B9C0"}}>Subscribe our Latest Newsletter</p>
          <div className="footer-newsletter" style={{marginTop:"10px"}}>
            <input type="email" placeholder="Enter Your E-Mail" />
            <button className="footer-newsletter-btn">Subscribe</button>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p style={{color:"rgb(182, 185, 192)"}}>©Total Technology Systems .2024 All Rights Reserved.</p>
        <div className="footer-socials">
          <a href="#facebook" className="social-icon"><FaFacebook /></a>
          <a href="https://x.com/total526501" className="social-icon"><BsTwitterX /></a>
          <a href="http://www.linkedin.com/in/total-technology-system-523363310" className="social-icon"><FaLinkedin /></a>
          <a href="#instagram" className="social-icon"><FaInstagram /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
