import React, { useEffect, useState } from 'react';
import './StatisticsSection.css';
import bgImg from '../Images/about-bg.png';

const StatisticsSection = () => {
    const [happyCustomers, setHappyCustomers] = useState(0);
    const [worksCompleted, setWorksCompleted] = useState(0);
    const [expertMembers, setExpertMembers] = useState(0);
    const [satisfactionRates, setSatisfactionRates] = useState(0);

    const animateNumber = (setValue, targetValue, duration = 2000) => {
        let startValue = 0;
        const increment = targetValue / (duration / 100);

        const updateNumber = () => {
            if (startValue < targetValue) {
                startValue += increment;
                if (startValue > targetValue) startValue = targetValue;
                setValue(Math.floor(startValue));
                requestAnimationFrame(updateNumber);
            }
        };
        updateNumber();
    };

    useEffect(() => {
        animateNumber(setHappyCustomers, 10000); // Target for happy customers
        animateNumber(setWorksCompleted, 20000); // Target for works completed
        animateNumber(setExpertMembers, 99);      // Target for expert members
        animateNumber(setSatisfactionRates, 100); // Target for satisfaction rates
    }, []);

    return (
        <div className="statistics-section" style={{ backgroundImage: `url(${bgImg})` }} >
            <div className="stat-container">
                <div className="stat-item">
                    <h2 style={{color:"#fff"}}>{happyCustomers}+</h2>
                    <p style={{color:"#B6B9C0"}}>HAPPY CUSTOMERS</p>
                </div>
                <div className="stat-item">
                    <h2 style={{color:"#fff"}}>{worksCompleted}+</h2>
                    <p style={{color:"#B6B9C0"}}>WORKS COMPLETED</p>
                </div>
                <div className="stat-item">
                    <h2 style={{color:"#fff"}}>{expertMembers}</h2>
                    <p style={{color:"#B6B9C0"}}>EXPERT MEMBERS</p>
                </div>
                <div className="stat-item">
                    <h2 style={{color:"#fff"}}>{satisfactionRates}%</h2>
                    <p style={{color:"#B6B9C0"}}>SATISFACTION RATES</p>
                </div>
            </div>
        </div>
    );
};

export default StatisticsSection;

