import React from 'react';
import './AboutUs.css';
import teamImage from '../Images/team.jpeg';
import { Link } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa'; 
const AboutUs = () => {
  return (
    <section className="toptech-section">
      <div className="toptech-container">

        
        <div className="image-container">
          <img src={teamImage} alt="Our Team at Work" className="team-image" />
        </div>

       
        <div className="content-container">
          <p className="section-title">WHO WE ARE</p>
          <h2>
            Get Exclusive IT Solutions & Services With <span className="highlight-text">TTS</span>
          </h2>
          <p className="description">
            We are an ISO 9001:2015 certified company dedicated to empowering businesses with
            cutting-edge software technology solutions tailored to their unique needs. With over 15
            years of experience as a leading IT company, we specialize in delivering innovative and 
            reliable services that drive efficiency, productivity, and growth for our clients.
            Our team of experienced professionals is committed to excellence, offering a 
            comprehensive range of IT, ITES, and geospatial services, including staff augmentation, 
            IT product development, and GIS solutions.
          </p>

          
          <div className="footer-section">
            <Link to="/Overview"><button className="btn-primary1">More About</button></Link>
            
            <a href="/profile_7.pdf" download className="btn-primary1" style={{textDecoration:"none"}}>
              <FaDownload style={{ marginRight: '8px' }} /> Download Brochure
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
