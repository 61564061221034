import React, { useState } from 'react';
import './Contact.css';
import Header from '../Components/Home/Header/Header';
import Footer from '../Components/Home/Footer/Footer';
import Logo from '../Components/Home/Logo/Logo';
import axios from 'axios';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required.';
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid.';
        }
        if (!formData.phone) newErrors.phone = 'Phone number is required.';
        if (!formData.subject) newErrors.subject = 'Subject is required.';
        if (!formData.message) newErrors.message = 'Message is required.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await axios.post('http://localhost:5000/contacts', formData);
                console.log('Form submitted successfully:', response.data);
                // Reset form
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                });
                setErrors({});
            } catch (error) {
                console.error('Error submitting form:', error);
                setErrors({ submit: 'There was an error submitting your message.' });
            }
        }
    };

    return (
        <>
            <Header />
            <div className="contact-container">
                <div className="contact-form">
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Your Name" 
                                value={formData.name} 
                                onChange={handleChange}
                            />
                            {errors.name && <span className="error">{errors.name}</span>}
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Enter E-Mail" 
                                value={formData.email} 
                                onChange={handleChange}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="form-row">
                            <input 
                                type="text" 
                                name="phone" 
                                placeholder="Phone Number" 
                                value={formData.phone} 
                                onChange={handleChange}
                            />
                            {errors.phone && <span className="error">{errors.phone}</span>}
                            <input 
                                type="text" 
                                name="subject" 
                                placeholder="Subject" 
                                value={formData.subject} 
                                onChange={handleChange}
                            />
                            {errors.subject && <span className="error">{errors.subject}</span>}
                        </div>
                        <textarea 
                            name="message" 
                            placeholder="Write Message" 
                            value={formData.message} 
                            onChange={handleChange}
                        ></textarea>
                        {errors.message && <span className="error">{errors.message}</span>}
                        {errors.submit && <span className="error">{errors.submit}</span>}
                        <button className='btn' type="submit">Send Message</button>
                    </form>
                </div>
            </div>
            <div className="map-container">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14964.220564708909!2d85.8103949!3d20.3393406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909005f32b597%3A0x4c836a6a55bee903!2sTotal%20technology%20system!5e0!3m2!1sen!2sin!4v1729383042928!5m2!1sen!2sin" 
                    width="100%" 
                    height="450" 
                    style={{ border: 0 }} 
                    allowFullScreen 
                    loading="lazy"
                ></iframe>
            </div>
            <Logo />
            <Footer />
        </>
    );
};

export default Contact;
