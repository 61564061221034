import React from 'react'
import Header from './Header/Header'
import Hero from './Hero/Hero'
import AboutUs from './AboutUs/AboutUs'
import About from './About/About'

import StatisticsSection from './StatisticsSection/StatisticsSection'
import Testimonial from './Testimonial/Testimonial'
import Logo from './Logo/Logo'
import VideoSection from './VideoSection/VideoSection'
import Footer from './Footer/Footer'


const Home = () => {
    return (
        <div>

            <Header />
            <Hero/>
            <AboutUs />
            <About/>
            <Testimonial/>
            <StatisticsSection/>
            <Logo/>
            <VideoSection/>
            <Footer/>
        </div>
    )
}

export default Home